import './App.css';
import Login from './Frontend/Login.js';

function App() {
  return (
    <div>
      <Login/>
    </div>
  );
}

export default App;
