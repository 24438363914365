import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyClWZcUQp217Q0zHyMDU0Z5hoYq1D72_Ww",
  authDomain: "ploustou-team.firebaseapp.com",
  projectId: "ploustou-team",
  storageBucket: "ploustou-team.appspot.com",
  messagingSenderId: "200665314793",
  appId: "1:200665314793:web:3e88efbbbe4a5526548778",
  measurementId: "G-7F48QLH8WH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };